@value primaryBright from '@advanza/css/vars.css';

.root {
    background-color: rgba(16, 24, 90, 0.4);
}

.headerText {
    font-family: Poppins, serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
}

.footer {
    display: flex;
    gap: 16px;
    justify-content: flex-end;
    align-items: end;
}

.icon {
    color: primaryBright;
}
