.root {
    background-color: #f1f3f4;
    border-radius: 5px;
}

.root input {
    height: 31px;
    background-color: transparent;
}
.root label {
    border-color: transparent;
    background-color: transparent;
    height: 31px;
}
.root.hasResults label {
    border-color: rgba(0, 0, 0, 0.2);
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom: none;
    background-color: #fff;
}
.results {
    position: absolute;
    z-index: 101;
    background-color: #fff;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1), 0 2px 25px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
}
.results:after {
    position: absolute;
    transition: opacity 0.2s ease;
    top: 0;
    left: 0;
    width: 100%;
    content: ' ';
    height: 100%;
    background-color: #fff;
    opacity: 0;
    z-index: -1;
}
.results.loading:after {
    opacity: 0.7;
    z-index: 1;
}
.result {
    display: block;
    width: 100%;
    padding: 5px 10px;
}

.result:hover,
.result:focus {
    background-color: #f7f7f7;
    outline: none;
}
