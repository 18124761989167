@value primary from '../input/vars.css';
.default,
.cta,
.yellow,
.yellowAlt {
    height: 36px;
    min-width: 100px;
    border-radius: 5px;
    background-color: #5788ff;
    padding: 0 10px 1px 10px;
    font-weight: bold;
    color: #fff;
    position: relative;
    font-size: 14px;
}

.default:not(:disabled):hover {
    background-color: #4166bf;
    box-shadow: 0 0 5px RGBa(0, 119, 204, 0.4);
    transition: 0.21s ease;
}

.bordered,
.borderedPoppins {
    height: 36px;
    min-width: 100px;
    font-size: 14px;
    font-weight: bold;
    border-radius: 5px;
    padding: 0 16px 1px 16px;
    color: #10185a;
    border: 1px solid #b6b9cd;
}

.bordered:hover,
.borderedPoppins:hover {
    background-color: rgba(87, 136, 255, 0.06);
}

.borderedPoppins {
    font-family: Poppins, sans-serif;
    background-color: #fff;
    height: 32px;
}

.text {
    font-weight: bold;
    font-family: Poppins, sans-serif;
    color: #3b6eea;
    font-size: 14px;
}

.text:hover,
.text:hover i {
    color: #000;
}

.text2 {
    color: #5788ff;
    font-size: 14px;
    line-height: 1.5;
}

.text2:hover,
.text2:hover i {
    color: #000;
}

.textReadMore {
    color: #5788ff;
    font-family: Mulish, sans-serif;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
}

.textReadMore:hover,
.textReadMore:hover i {
    color: #000;
}

.delete,
.discard {
    color: red;
    height: 36px;
    border-radius: 5px;
    padding: 0 16px 1px 16px;
    background-color: #fff;
    border: 1px solid red;
}

.discard {
    font-weight: bold;
}

.subtext {
    font-size: 10px;
}

.delete:hover,
.discard:hover {
    background-color: rgba(200, 200, 200, 0.2);
}

.default i,
.bordered i,
.borderedPoppins i {
    font-size: 18px;
    margin-right: 4px;
}

.text i {
    color: #5788ff;
}

.default i {
    color: #fff !important;
    vertical-align: sub;
    font-size: 16px;
}

.cta {
    font-size: 16px;
    background-color: #fecc90;
    color: #10185a;
    height: 46px;
}

.cta i {
    color: #10185a;
}

.yellow,
.yellowAlt {
    background-color: #fecc90;
    color: #10185a;
    border: 1px solid #b6b9cd;
}

.yellowAlt {
    border: none;
    font-weight: 600;
}

.yellow:hover,
.yellowAlt:hover {
    background-color: #e7b57a;
}

.disabledStyle:disabled {
    background-color: rgba(200, 200, 200, 0.7);
    cursor: not-allowed;
}

.text:disabled,
.text:disabled i {
    background-color: transparent;
    color: rgba(200, 200, 200, 0.7);
}

.cta i {
    color: #10185a;
}

.link {
    text-decoration: underline;
    color: #5788ff;
}

.link:hover {
    color: #10185a;
}

.underline {
    text-decoration: underline;
}

.primary {
    background-color: #10185a;
    color: #fff;
    padding: 9px 16px 11px;
    border-radius: 4px;
}

.wide {
    min-width: 100px;
    border-radius: 5px;
    background-color: #5788ff;
    font-weight: 500;
    color: #fff;
    font-size: 14px;
    padding: 13px 24px;
    position: relative;
}

.primaryText,
.primaryText2 {
    color: #10185a;
    text-decoration: underline;
    font-weight: 600;
    padding: 13px 24px;
    font-size: 12px;
}

.primaryText2 {
    padding: 0;
    font-size: 14px;
    line-height: 1.3;
}

.blue {
    font-size: 14px;
    font-weight: bold;
    border-radius: 5px;
    padding: 0 16px 1px 16px;
    border: 1px solid #5788ff;
    color: #5788ff;
}

.blue:hover {
    border: 1px solid #3551a3;
}

.blue i {
    color: #5788ff;
}

.blue:hover i {
    color: #3551a3;
}

.blue:hover {
    background-color: rgba(87, 136, 255, 0.06);
    border: 1px solid #3551a3;
    color: #3551a3;
}

.square {
    color: #417ef6;
    text-transform: uppercase;
    font-size: 12px;
}

.square > div > div:last-of-type {
    padding-right: 0;
}

.square i {
    padding-bottom: 8px;
    color: #417ef6 !important;
    font-size: 20px;
}

.newStyle {
    padding: 9px 20px;
    width: auto;
    height: auto;
    border-radius: 4px;
    background-color: #5788ff;
    color: white;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.57;
    text-align: center;
}

.newStyle:disabled {
    background-color: #9ca5ad;
}

.newStyle > i {
    font-weight: bold;
}

.basic {
    border-radius: 4px;
    display: flex;
    padding: 9px 32px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    font-family: Poppins, 'Work Sans', Helvetica, Arial, serif;
    color: primary;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;

    .icon {
        font-size: 20px;
    }

    &.noText {
        padding: 7px;
        .icon {
            font-size: 16px;
        }
    }

    &:disabled {
        color: #fff;
        background: #9ca5ad;
        border: 1px solid transparent;
        cursor: not-allowed;
        .icon {
            color: #fff;
        }
    }
}

.appearance_primary {
    background: #5788ff;
    color: #fff;

    .icon {
        color: #fff;
    }
}

.appearance_outlined_primary {
    border: 1px solid #5788ff;
    padding: 8px 31px;
    background: #fff;
    color: #5788ff;

    .icon {
        color: #5788ff;
    }
}

.appearance_secondary {
    border: 1px solid #9ca5ad;
    padding: 8px 31px;
    background: #fff;
}

.appearance_outlined_primary_less_prominent {
    border: 1px solid #dbdce0;
    background: #fff;
    color: #5788ff;

    .icon {
        color: #5788ff;
    }
}

.appearance_tertiary {
    background-color: #fecc90;
}

.appearance_whatsapp {
    border: 1px solid #29a71a;
    background: #29a71a;
    color: #fff;
}

.appearance_whatsapp_outlined {
    border: 1px solid #29a71a;
    background: #fff;
    color: #29a71a;
}

.appearance_text_primary {
    padding: 0;
    color: #5788ff;

    .icon {
        color: #5788ff;
    }

    &:hover {
        color: #3551a3;

        .icon {
            color: #3551a3;
        }
    }

    &:disabled {
        background: transparent;
        color: #9ca5ad;

        .icon {
            color: #9ca5ad;
        }
    }
}

/*  device supports hover events and has an accurate pointing device, such as a mouse */
@media (hover: hover) and (pointer: fine) {
    .appearance_primary:not(:disabled) {
        &:hover {
            background: #3551a3;
        }
    }

    .appearance_outlined_primary:not(:disabled) {
        &:hover {
            border: 1px solid #3551a3;
            background: #eff8ff;
            color: var(--Primary-Dark, #3551a3);
            .icon {
                color: #3551a3;
            }
        }
    }

    .appearance_whatsapp_outlined:not(:disabled) {
        &:hover {
            border: 1px solid #1d7712;
            background: #eaf6e8;
            color: #1d7712;
        }
    }

    .appearance_whatsapp:not(:disabled) {
        &:hover {
            border: 1px solid #1d7712;
            background: #1d7712;
        }
    }

    .appearance_outlined_primary_less_prominent:not(:disabled) {
        &:hover {
            border: 1px solid #5788ff;
            background: #eff8ff;
        }
    }

    .appearance_secondary:not(:disabled) {
        &:hover {
            background: #edeef0;
        }
    }

    .appearance_tertiary:not(:disabled) {
        &:hover {
            background: #fa7;
        }
    }
}

/* for touch devices use :active instead of :hover to change the button color during a tap */
@media (pointer: coarse) {
    .appearance_primary:active {
        background: #3551a3;
    }
}
