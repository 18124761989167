@value primary: #10185a;
@value primaryBright: #5788ff;
@value primaryLighter2: #c4dcff;
@value primaryLightest2: #eff8ff;

@value secondaryBase: #FA7;
@value secondaryDarker: #F1612A;
@value secondaryLighter2: #ffe6c8;
@value secondaryLightest: #FFF3E5;

@value tertiaryBase: #0ea66b;
@value tertiaryDark: #006F44;
@value tertiaryLighter: #c3fee3;

@value review: #FFB700;
@value text: #202124;
@value textLight: #202124b3;
@value grey-700: #9B9C9F;
@value warning: #cf3131;
@value link: #5788ff;
@value notification: #E7E8EB;
@value btnDisabled: #c8c8c8b3;

@value bg: #0000560a;
@value bgWhite: #ffffff;
@value bgLight: #F9FAFB;
@value bgLighter: #F9FAFB;
@value border: #DADCE0;

@value trustooOrange: #f1612a;
@value trustooGreen: #1aa225;
@value trustooRed: #e53935;

@value neutralsLightDark: #c0c1c7;
@value neutralsLightDarker: #aaaab0;
@value grey-500: #dbdce0;
@value neutralsDarkLighter2: #9ca5ad;

/*new colors for Demand Dashboard redesign @2024-12*/
@value trustooStatusDefault: #5788ff;
@value trustooStatusGreen: #1aa225;
@value trustooStatusOrange: #f1612b;
@value trustooStatusRed: #f00;

@value bodyDark:#404145;
@value bodyLight:#f9f9f9;
@value dark:#232852;
@value dateColor:#80868b;
@value primary-50:#eff3ff;
@value primary-500:#5A8BFC;
@value neutral-200: #e5e5e5;
@value shadow: #8E8E8E3F;
